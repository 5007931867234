@font-face {
font-family: 'Kiona Regular';
font-style: normal;
font-weight: normal;
src: local('Kiona Regular1'), url('../../../../../fonts/DBY/Kiona-Regular.woff') format('woff');
font-display: swap;
}
@font-face {
font-family: 'Kiona Bold';
font-style: normal;
font-weight: normal;
src: local('Kiona Bold'), url('../../../../../fonts/DBY/Kiona-Bold.woff') format('woff');
font-display: swap;
}
@font-face {
font-family: 'KIONA SemiBold';
font-style: normal;
font-weight: normal;
src: local('KIONA SemiBold'), url('../../../../../fonts/DBY/KIONA-SemiBold.woff') format('woff');
font-display: swap;
}
@font-face {
font-family: 'KIONA Light';
font-style: normal;
font-weight: normal;
src: local('KIONA Light'), url('../../../../../fonts/DBY/KIONA-Light.woff') format('woff');
font-display: swap;
}
@font-face {
    font-family: 'Champagne & Limousines';
    font-style: normal;
    font-weight: normal;
    src: local('Champagne & Limousines'), url('../../../../../fonts/DBY/Champagne-Limousines.woff') format('woff');
    font-display: swap;
}
@font-face {
font-family: 'Champagne & Limousines Bold';
font-style: normal;
font-weight: normal;
src: local('Champagne & Limousines Bold'), url('../../../../../fonts/DBY/Champagne-Limousines Bold.woff') format('woff');
font-display: swap;
}
